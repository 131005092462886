/*
 * Add service specific styles here in case it is unavoidable.
 * Please prefer using atomic styles of the UIKIT instead!
 */
.StarterTemplate {
}

.btn.btn-muted.custom-mute-button {
    color: black !important;
}
.btn.btn-muted.custom-mute-button:is(:hover, :active, .active, :focus-visible) {
    color: var(--o) !important;
}

/* scroll effect to show there are more items in the table */
.scroll-effect-table:before {
    content: '';
    height: 20px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: -21px;
    width: 100%;
    background: linear-gradient(0deg, hsla(var(--1), var(--2), var(--3), 1), hsla(var(--1), var(--2), var(--3), 0));
}

.reassignAssetsTable {
    height: 72vh;
}
